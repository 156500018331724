import React from 'react'
import { Container } from '../PageContainer'
import { Button } from '@material-ui/core'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import './home.scss'

const BannerPhoto = () => {
  return (
    <section
      style={{ position: 'relative' }}
      className="verticalPadding whiteBackground"
    >
      <Container>
        <div style={{ position: 'relative', zIndex: 5 }}>
          <h2 className="blueColor">
            Doctor consultations have <br />
            never been more convenient.
          </h2>
          <p className="blueColor servicesText">No travel hassles or delays</p>
          <Link to="/" className="responsiveCenter">
            <Button variant="contained" className="servicesButton">
              LEARN MORE ABOUT OUR SERVICES
            </Button>
          </Link>
        </div>
        <div className="learnmoreImage responsiveCenter">
          <StaticImage
            src="../../assets/learnmoreimage.jpg"
            alt="mother-laptop"
            layout="constrained"
            quality="50"
          />
        </div>
      </Container>
    </section>
  )
}

export default BannerPhoto
