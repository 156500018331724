import BannerPhoto from 'components/Home/BannerPhoto'
import TrustedBy from 'components/Home/TrustedBy'
import AsFeaturedIn from 'components/Home/AsFeaturedIn'
import HeroHome from 'components/Home/Hero'
import ThreeSteps from 'components/Home/ThreeSteps'
import Layout from 'components/Layout'
import React from 'react'
import AppStateProvider from './../state'
import 'components/Home/home.scss'
import { JsonLd } from 'gatsby-plugin-next-seo'

class RootIndex extends React.Component {
  render() {
    return (
      <AppStateProvider>
        <Layout
          navClasses="transparent"
          title="Online Doctors, Prescriptions &amp; Medical Certificates | SwiftDoc"
        >
          <JsonLd
            json={{
              '@context': 'http://www.schema.org',
              '@type': 'WebPage',
              name: 'SwiftDoc',
              url: 'https://swiftdoc.com',
              description:
                'SwiftDoc is a telehealth service for doctors consulation, doctors certificates, instants scripts, online healthcare and more ',
              publisher: {
                '@type': 'Corporation',
                name: 'SwiftDoc',
              },
            }}
          />
          <HeroHome />
          <AsFeaturedIn />
          {/* <DownloadPromote backgroundColor={"#fff"}/> */}
          <ThreeSteps />
          <BannerPhoto />
          <TrustedBy />
        </Layout>
      </AppStateProvider>
    )
  }
}

export default RootIndex
