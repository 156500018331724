import React from 'react';
import { Container } from '../PageContainer';
import { Grid, Typography, Button } from '@material-ui/core';
import { Link } from 'gatsby'
import './home.scss'
import StepImage from '../StepImage';
import CalendarIcon from '../../assets/icons/stepImages/calendar.svg'
import CategoryIcon from '../../assets/icons/stepImages/category.svg'
import MedicIcon from '../../assets/icons/stepImages/medic.svg'
import VideoIcon from '../../assets/icons/stepImages/video.svg'

const ThreeSteps = () => {

  return (
    <section className="lightBlueBackground stepsSection">
      <Container>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <h2 className="blackColor">
              Making health easier
            </h2>
            <Typography className="darkGreyColor" style={{color: '#6A7B93'}}>Interact with our online doctors in <span style={{color: "#016CB2"}}>4 easy steps.</span></Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="center" spacing={4} alignItems="center">
              <Grid item xl={3} md={3} xs={12} className="horizontalCenterAlignCol">
                <StepImage 
                  icon={CategoryIcon}
                  title={"Choose a service"}
                  content={"Choose a service to get started"}
                />
              </Grid>
              <Grid item xl={3} md={3} xs={12} className="horizontalCenterAlignCol">
                <StepImage 
                  icon={MedicIcon}
                  title={"Choose a doctor"}
                  content={"After choosing a service, choose one of our Australia based online doctors"}
                />
              </Grid>
              <Grid item xl={3} md={3} xs={12} className="horizontalCenterAlignCol">
                <StepImage                   
                  icon={CalendarIcon}
                  title={"Choose a time"}
                  content={"Choose a time convenient for you"}
                />
              </Grid>
              <Grid item xl={3} md={3} xs={12} className="horizontalCenterAlignCol">
                <StepImage 
                  icon={VideoIcon}
                  title={"Have your video consultation"}
                  content={"Be seen by one of our expert doctors at a time that suits you from anywhere in Australia."}
                  />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <a href="https://app.swiftdoc.com">
              <Button variant="contained" className="bookAppointmentButton2">
                BOOK AN APPOINTMENT
              </Button>
            </a>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ThreeSteps;