import React from 'react'
import { Container } from '../PageContainer'
import { Grid, Typography } from '@material-ui/core'
import { StaticImage } from "gatsby-plugin-image"
import './home.scss'

const TrustedBy = () => {

  return (
    <section className="whiteBackground">
      <Container>
        <div style={{paddingTop: '5vh', paddingBottom: '10vh'}}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className="blueColor">
                Trusted By
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StaticImage src="../../assets/trusted-by.jpg"   imgClassName="trustedByImage" alt="trusted-by" />
            </Grid>
            <Grid item xs={12}>
              <div className= "trustedByText">
                <Typography
                  className="blueColor trustedByText"
                  style={{ color: '#016CB2' }}
                >
                  And thousands of other pharmacies nationwide. <br />
                  We are registered with Argus, Medisecure and Rural Health
                  Alliance.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  )
}

export default TrustedBy