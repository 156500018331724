import React from 'react'
import { Container } from '../PageContainer'
import { Grid, Typography } from '@material-ui/core'
import './home.scss'
import { StaticImage } from 'gatsby-plugin-image'

const AsFeaturedIn = () => {
  return (
    <section className="whiteBackground">
      <Container>
        <div style={{ paddingTop: '5vh', paddingBottom: '10vh' }}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography className="featuredIn">As featured in</Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="featuredInSmLogo">
                <StaticImage
                  src="../../assets/images/sm-logo.svg"
                  placeholder="none"
                  layout="constrained"
                  alt="The Sydney Morning Herald logo"
                  imgClassName="featuredInSmLogo"
              

                />
              </div>

            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  )
}

export default AsFeaturedIn
