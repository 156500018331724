import React, { useState, useEffect } from 'react'
import './stepImage.scss'


const StepImage = (props) => {

    return (
        <div className="stepContainer">
            <div className="stepIconContainer">
                <div className="stepIcon"><img className="stepIconImage" src={props.icon} alt='StepIcon'/></div>
            </div>
            <div className="stepTextContainer">
                <div>
                    <h4 className="stepTitle">{props.title}</h4>
                </div>
                <div>
                    <p className="stepContent">{props.content}</p>
                </div>
            </div>
        </div>
    )
}

export default StepImage;