import React from 'react'
import { Container } from 'components/PageContainer'
import { Button, Grid } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
import './home.scss'

const HeroHome = () => {
  return (
    <header className="wave">
      <Container>
        <Grid container spacing={0} justify="space-around" alignItems="center">
          <Grid item xs={12} md={5} className="leftHeaderContainer">
            <div className="heroTextContainer">
              <h1 className="whiteColor title" style={{ textAlign: 'left' }}>
                Online Doctor
                <br /> Consultations
              </h1>
              <p className="subtitle whiteColor">
                DIRECT FROM YOUR <span className="boldSubtitle">PHONE</span> OR{' '}
                <span className="boldSubtitle">DESKTOP</span>
              </p>
              <p className="paragraph responsiveCenter">
                See a Registered Australian Doctor for diagnosis, treatment,
                prescriptions, medical certificates, referrals, pathology,
                imaging and more...
              </p>
              <a href="https://app.swiftdoc.com" className="responsiveCenter">
                <Button className="bookAppointmentButtonV2">
                  BOOK AN APPOINTMENT
                </Button>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div class="rightImage responsiveCenter">
              <StaticImage
                src="../../assets/grupo49.png"
                layout="constrained"
                alt="Hero image"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
      <div style={{ height: 150, overflow: 'hidden', marginBottom: '-3px' }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: '100%', width: '100%' }}
        >
          <path
            d="M0.00,49.98 C149.99,150.00 353.83,62.66 501.12,22.20 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: 'none', fill: '#FFF' }}
          ></path>
        </svg>
      </div>
    </header>
  )
}

export default HeroHome
